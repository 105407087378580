

















































































































































































































































































































































import Vue from "vue";
import confetti from "canvas-confetti";
import MealOptionSelection from "@/components/rsvp/MealOptionSelection.vue";

interface GuestName {
  first: string;
  last: string;
}

interface GuestNameInput {
  first: string | undefined;
  last: string | undefined;
}

interface GuestDetails {
  name: GuestName;
  plus_one_count: number;
}

interface SubmitRSVPRequest {
  is_attending: boolean;
  name: GuestName;
  meal_option: string | undefined;
  plus_one: PlusOneDetails | undefined;
}

interface PlusOneDetailInput {
  name: GuestNameInput;
  mealOption: string | undefined;
}

interface PlusOneDetails {
  name: GuestName;
  meal_option: string;
}

export default Vue.extend({
  name: "RSVP",
  components: { MealOptionSelection },
  data: () => ({
    step: 0,
    guestNameInput: {} as GuestNameInput,
    guestDetails: undefined as GuestDetails | undefined,
    mealOptions: [],
    acceptanceSelection: "",
    inputOptions: {
      mealOption: undefined as string | undefined,
      hasPlusOne: false,
      plusOne: {
        name: {} as GuestNameInput,
        mealOption: undefined as string | undefined,
      } as PlusOneDetailInput,
    },
    errorMessage: undefined as string | undefined,
    errorStates: {
      acceptSelection: false,
      mealOption: false,
      plusOne: {
        name: {
          first: false,
          last: false,
        },
        mealOption: false,
      },
    },
    submitResult: undefined as string | undefined,
  }),
  computed: {
    showError() {
      return this.errorMessage !== undefined;
    },
  },
  methods: {
    resetToStep(newStep: number) {
      if (newStep === 0) {
        this.guestNameInput = {} as GuestNameInput;
        this.guestDetails = undefined;

        this.mealOptions = [];
      }

      if (newStep <= 2) {
        this.acceptanceSelection = "";

        this.inputOptions.mealOption = undefined;
        this.inputOptions.hasPlusOne = false;
        this.inputOptions.plusOne.name = {} as GuestNameInput;
        this.inputOptions.plusOne.mealOption = undefined;
      }

      this.step = newStep;
    },
    resetErrors() {
      this.errorMessage = undefined;

      this.errorStates.acceptSelection = false;
      this.errorStates.mealOption = false;
      this.errorStates.plusOne.name.first = false;
      this.errorStates.plusOne.name.last = false;
      this.errorStates.plusOne.mealOption = false;
    },
    loadGuestDetails() {
      if (
        this.guestNameInput.first === undefined ||
        this.guestNameInput.last === undefined ||
        this.guestNameInput.first.length === 0 ||
        this.guestNameInput.last.length === 0
      ) {
        this.errorMessage = "Please enter your name to continue.";
      } else {
        const url = new URL(
          process.env.VUE_APP_RSVP_API_HOSTNAME + "/load_guest_details"
        );
        url.searchParams.set("first_name", this.guestNameInput.first.trim());
        url.searchParams.set("last_name", this.guestNameInput.last.trim());

        this.resetErrors();
        this.step = 1;

        fetch(url.toString(), {
          method: "GET",
          cache: "no-store",
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            } else {
              return Promise.reject({
                type: "UnknownError",
                error: null,
              });
            }
          })
          .then((json) => {
            if (json.result === "Found") {
              this.guestDetails = {
                name: {
                  first: json.details.name.first,
                  last: json.details.name.last,
                },
                plus_one_count: json.details.plus_one_count,
              };
            } else if (json.result === "AlreadySubmitted") {
              return Promise.reject({
                type: "AlreadySubmittedError",
                error: null,
              });
            } else {
              return Promise.reject({
                type: "GuestNotFoundError",
                error: null,
              });
            }
          })
          .then(() => {
            return this.loadOptions();
          })
          .catch((error) => {
            if (error.type === undefined || error.type === "UnknownError") {
              if (error.error instanceof TypeError) {
                this.errorMessage =
                  "A network error occurred while processing your request. Please try again.";
              } else {
                this.errorMessage =
                  "An unknown error occurred while processing your request. Please try again.";
              }

              this.step = 0;
            } else if (error.type === "AlreadySubmittedError") {
              this.errorMessage = "You have already submitted your RSVP!";
              this.resetToStep(0);
            } else if (error.type === "GuestNotFoundError") {
              this.errorMessage = "A guest by that name could not be found.";
              this.step = 0;
            }
          });
      }
    },
    loadOptions() {
      return fetch(process.env.VUE_APP_RSVP_API_HOSTNAME + "/load_options", {
        method: "GET",
        cache: "no-store",
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            return Promise.reject({
              type: "UnknownError",
              error: null,
            });
          }
        })
        .then((json) => {
          this.mealOptions.push(...(json.meals as never[]));
          this.step = 2;

          this.resetErrors();
        });
    },
    submit() {
      this.resetErrors();

      if (this.guestDetails === undefined) {
        this.errorMessage =
          "An unknown error occurred while processing your request. Please try again.";
        this.resetToStep(0);
      } else {
        if (this.acceptanceSelection === "") {
          this.errorStates.acceptSelection = true;
          this.errorMessage = "Please make a selection.";
          return;
        }

        if (this.acceptanceSelection === "accept") {
          if (
            this.inputOptions.mealOption === undefined ||
            this.inputOptions.mealOption.length === 0
          ) {
            this.errorStates.mealOption = true;
            this.errorMessage = "You must select a meal option!";
            return;
          } else {
            if (this.inputOptions.hasPlusOne) {
              if (
                this.inputOptions.plusOne.name.first === undefined ||
                this.inputOptions.plusOne.name.first.length === 0
              ) {
                this.errorStates.plusOne.name.first = true;
                this.errorMessage = "Please enter your plus one's details.";
                return;
              }

              if (
                this.inputOptions.plusOne.name.last === undefined ||
                this.inputOptions.plusOne.name.last.length === 0
              ) {
                this.errorStates.plusOne.name.last = true;
                this.errorMessage = "Please enter your plus one's details.";
                return;
              }

              if (this.inputOptions.plusOne.mealOption === undefined) {
                this.errorStates.plusOne.mealOption = true;
                this.errorMessage = "Please enter your plus one's details.";
                return;
              }
            }
          }
        }

        this.step = 3;

        let rsvpData: SubmitRSVPRequest = {
          is_attending: this.acceptanceSelection === "accept",
          name: this.guestDetails.name,
          meal_option: this.inputOptions.mealOption,
          plus_one: this.inputOptions.hasPlusOne
            ? {
                name: {
                  first: this.inputOptions.plusOne.name.first as string,
                  last: this.inputOptions.plusOne.name.last as string,
                },
                meal_option: this.inputOptions.plusOne.mealOption as string,
              }
            : undefined,
        };

        fetch(process.env.VUE_APP_RSVP_API_HOSTNAME + "/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(rsvpData),
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            } else {
              return Promise.reject({
                type: "UnknownError",
                error: null,
              });
            }
          })
          .then((json) => {
            this.submitResult = json.result;

            if (this.submitResult === "GuestNotFound") {
              this.errorMessage =
                "An unknown error occurred while processing your request. Please try again.";
              this.resetToStep(0);
            } else if (this.submitResult === "InvalidMealOption") {
              this.errorMessage = "Please select a meal option.";
              this.step = 2;
            } else if (this.submitResult === "InvalidPlusOneDetails") {
              if (
                this.guestDetails !== undefined &&
                this.guestDetails.plus_one_count > 0
              ) {
                this.errorMessage = "Please enter your plus one's details.";
                this.step = 2;
              } else {
                this.errorMessage =
                  "An unknown error occurred while processing your request. Please try again.";
                this.resetToStep(2);
              }
            } else if (this.submitResult === "AlreadySubmitted") {
              this.errorMessage = "You have already submitted your RSVP!";
              this.resetToStep(0);
            } else if (this.submitResult === "SuccessAttending") {
              this.step = 4;
              this.showConfetti();
            } else if (this.submitResult === "SuccessNotAttending") {
              this.step = 4;
            } else {
              return Promise.reject({
                type: "UnknownError",
                error: null,
              });
            }
          })
          .catch((error) => {
            if (error.error instanceof TypeError) {
              this.errorMessage =
                "A network error occurred while processing your request. Please try again.";
            } else {
              this.errorMessage =
                "An unknown error occurred while processing your request. Please try again.";
            }

            this.step = 2;
          });
      }
    },
    randomInRange(min: number, max: number) {
      return Math.random() * (max - min) + min;
    },
    showConfetti() {
      const duration = 1500;
      const animationEnd = Date.now() + duration;
      const colors = ["#c3aa82", "#000000", "#722F37", "#AE4753", "#1F1F1F"];

      const interval = setInterval(() => {
        let timeLeft = animationEnd - Date.now();
        if (timeLeft <= 0) {
          return clearInterval(interval);
        }
        const particleCount = 200 * (timeLeft / duration);
        confetti({
          startVelocity: 30,
          spread: 360,
          zIndex: 999,
          particleCount: particleCount,
          origin: { x: this.randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
          colors: colors,
        });
        confetti({
          startVelocity: 30,
          spread: 360,
          zIndex: 999,
          particleCount: particleCount,
          origin: { x: this.randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
          colors: colors,
        });
      }, 250);

      (function frame() {
        function shuffle(array: string[]) {
          return array.sort(() => Math.random() - 0.5);
        }

        let x = 0;

        do {
          for (let y = 0.0; y < 1.0; y += 0.1) {
            confetti({
              particleCount: 2,
              angle: x == 0 ? 60 : 120,
              spread: 55,
              origin: { x: x, y: y },
              colors: shuffle(colors),
            });
          }

          x += 1;
        } while (x <= 1);

        if (Date.now() < animationEnd) {
          requestAnimationFrame(frame);
        }
      })();
    },
  },
});
