















































import Vue from "vue";

export default Vue.extend({
  name: "MealOptionSelection",
  props: ["mealOptions", "selectedOption", "hasError"],
});
